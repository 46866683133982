import { BlokProps } from "@/helpers/StoryblokHelpers"
import { StoryblokPageTemplates } from "@/store/story-store"
import {
  ArticlePageStoryblok,
  ClinicPageStoryblok,
  FactAdvicePageStoryblok,
  FaqStoryblok,
  PageStoryblok
} from "@/types/component-types"
import { getPlainText } from "@/utils/richTextToPlain"
import { useMemo } from "react"
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer"

export const useSeoArticleScript = (story: StoryblokPageTemplates, featuredImage?: string) => {
  return useMemo(
    () => ({
      "@context": "http://schema.org",
      "@type": "Article",
      headline: story?.name,
      author: {
        "@type": "Person",
        name: story?.content.author
      },
      datePublished: story?.first_published_at,
      dateModified: story?.published_at,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": process.env.HOME_URL
      },
      ...(featuredImage && { image: featuredImage }),
      articleSection: story?.content.seo_schema_article_section,
      publisher: {
        "@type": "Organization",
        name: "Doktor.se"
      }
    }),
    [
      featuredImage,
      story?.content.author,
      story?.content.seo_schema_article_section,
      story?.first_published_at,
      story?.name,
      story?.published_at
    ]
  )
}

export const useSeoFaqScript = (
  blok:
    | BlokProps<ArticlePageStoryblok>["blok"]
    | BlokProps<FactAdvicePageStoryblok>["blok"]
    | BlokProps<ClinicPageStoryblok>["blok"]
    | BlokProps<PageStoryblok>["blok"]
) => {
  return useMemo(() => {
    if (blok.seo_schema_faq && (blok.seo_schema_faq as Array<{ answer: string; question: string }>).length > 0) {
      return {
        "@context": "http://schema.org",
        "@type": "FAQPage",
        mainEntity: [
          (blok.seo_schema_faq as Array<{ answer: string; question: string }>).map(
            (item: { answer: string; question: string }) => ({
              "@type": "Question",
              name: item.question,
              acceptedAnswer: {
                "@type": "Answer",
                text: item.answer
              }
            })
          )
        ]
      }
    }
    if (blok.faq && (blok.faq as FaqStoryblok[]).length > 0) {
      return {
        "@context": "http://schema.org",
        "@type": "FAQPage",
        mainEntity: [
          (blok.faq as FaqStoryblok[]).map((item: FaqStoryblok) => ({
            "@type": "Question",
            name: item.question,
            acceptedAnswer: {
              "@type": "Answer",
              text: getPlainText(item.answer as StoryblokRichtext)
            }
          }))
        ]
      }
    }
  }, [blok])
}

export const useSeoMedicalConditionScript = (story: StoryblokPageTemplates) => {
  return useMemo(() => {
    if (story?.content.seo_schema_medical_condition_name) {
      return {
        "@context": "http://schema.org",
        "@type": "MedicalCondition",
        name: story?.content.seo_schema_medical_condition_name,
        alternateName: story?.content.seo_schema_medical_condition_alternate_name,
        description: story?.content.seo_schema_medical_condition_description,
        riskFactor: {
          "@type": "MedicalRiskFactor",
          name: story?.content.seo_schema_medical_condition_cause
        },
        possibleTreatment: {
          "@type": "MedicalTherapy",
          name: story?.content.seo_schema_medical_condition_possible_treatment
        },
        signOrSymptom: {
          "@type": "MedicalSymptom",
          name: story?.content.seo_schema_medical_condition_symptom
        },
        associatedAnatomy: {
          "@type": "AnatomicalStructure",
          name: story?.content.seo_schema_medical_condition_anatomy
        }
      }
    }
  }, [story])
}

export const useSeoMedicalWebPageScript = (story: StoryblokPageTemplates) => {
  return useMemo(() => {
    if (story?.content.seo_schema_medical_condition_name) {
      return {
        "@context": "http://schema.org",
        "@type": "MedicalWebPage",
        headline: story?.name,
        about: {
          "@type": "MedicalCondition",
          name: story?.content.seo_schema_medical_condition_name
        },
        medicalAudience: {
          "@type": "MedicalAudience",
          name: "Patients"
        }
      }
    }
  }, [story])
}

export const useSeoHowToScript = (story: StoryblokPageTemplates) => {
  return useMemo(() => {
    if (story?.content.seo_schema_how_to) {
      return {
        "@context": "http://schema.org",
        "@type": "HowTo",
        name: story?.name,
        step: (story?.content.seo_schema_how_to as Array<{ step: string }>).map(s => ({
          "@type": "HowToStep",
          text: s.step
        }))
      }
    }
  }, [story])
}

export const useSeoMedicalOrganizationScript = (blok: BlokProps<ClinicPageStoryblok>["blok"]) => {
  return useMemo(
    () => ({
      "@context": "http://schema.org",
      "@type": "MedicalOrganization",
      name: blok.seo_schema_medical_organization_name,
      address: {
        "@type": "PostalAddress",
        streetAddress: blok.seo_schema_medical_organization_address_street,
        postalCode: blok.seo_schema_medical_organization_address_postal,
        addressCountry: blok.seo_schema_medical_organization_address_country
      },
      contactPoint: {
        "@type": "ContactPoint",
        telephone: blok.seo_schema_medical_organization_address_telephone
      }
    }),
    [blok]
  )
}

export const useSeoOpeningHoursScript = (blok: BlokProps<ClinicPageStoryblok>["blok"]) => {
  return useMemo(
    () => ({
      "@context": "http://schema.org",
      "@type": "OpeningHoursSpecification",
      dayOfWeek: blok.seo_schema_opening_hours_days as Array<string>,
      opens: blok.seo_schema_opening_hours_opens,
      closes: blok.seo_schema_opening_hours_closes
    }),
    [blok]
  )
}

export const useSeoMedicalTherapyScript = (blok: BlokProps<PageStoryblok>["blok"]) => {
  return useMemo(() => {
    if (blok.seo_schema_medical_therapy_name) {
      return {
        "@context": "http://schema.org",
        "@type": "MedicalTherapy",
        name: blok.seo_schema_medical_therapy_name,
        description: blok.seo_schema_medical_therapy_description,
        therapyType: {
          "@type": "MedicalSpecialty",
          name: blok.seo_schema_medical_therapy_type
        },
        contraindication: {
          "@type": "MedicalContraindication",
          name: blok.seo_schema_medical_therapy_contraindication
        },
        seriousAdverseOutcome: {
          "@type": "MedicalEntity",
          name: blok.seo_schema_medical_therapy_serious_adverse_outcome
        },
        procedureType: blok.seo_schema_medical_therapy_procedure_type
      }
    }
  }, [blok])
}

export const useSeoProductScript = (blok: BlokProps<PageStoryblok>["blok"]) => {
  return useMemo(() => {
    if (blok.seo_schema_product_name) {
      return {
        "@context": "http://schema.org",
        "@type": "Product",
        name: blok.seo_schema_product_name,
        description: blok.seo_schema_product_description,
        offers: {
          "@type": "Offer",
          priceCurrency: blok.seo_schema_product_price_currency,
          price: blok.seo_schema_product_price,
          availability: "http://schema.org/InStock"
        }
      }
    }
  }, [blok])
}

import {
  NODE_BR,
  NODE_CODEBLOCK,
  NODE_HEADING,
  NODE_HR,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
  StoryblokRichtext,
  StoryblokRichtextContent
} from "storyblok-rich-text-react-renderer"

const renderNode = (node: StoryblokRichtextContent, addNewlines: boolean) => {
  if (node.type === "text") {
    return node.text
  } else if (
    [NODE_PARAGRAPH, NODE_HEADING, NODE_CODEBLOCK, NODE_QUOTE, NODE_OL, NODE_UL, NODE_LI, NODE_HR, NODE_BR].includes(
      node.type
    )
  ) {
    return node.content?.length ? `${renderNodes(node.content, addNewlines)}${addNewlines ? "\n\n" : " "}` : ""
  }

  return null
}

const renderNodes = (nodes: StoryblokRichtext["content"], addNewlines: boolean): string =>
  nodes
    .map(node => renderNode(node, addNewlines))
    .filter(node => node !== null)
    .join("")
    .replace(/[^\S\r\n]{2,}/g, " ")

export interface GetPlainTextOptions {
  addNewlines?: boolean
}

export const getPlainText = (richtext: StoryblokRichtext, { addNewlines }: GetPlainTextOptions = {}): string => {
  if (!richtext?.content?.length) {
    return ""
  }

  const text = renderNodes(richtext.content, addNewlines !== undefined ? addNewlines : true)

  return text
}
